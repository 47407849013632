import { useQuery } from 'react-query';
import { getVariables, IWebVariables } from '~/domain/variable';
import { defaultOnError } from '~/domain/error';
import type { AxiosError } from 'axios';

export const useVariablesQuery = {
  queryKey: 'getVariables',
  queryFn: () => getVariables(),
};

export const useVariables = ({
  suspense,
  refetchOnMount,
}: { suspense?: boolean; refetchOnMount?: boolean | 'always' } = {}) =>
  useQuery<IWebVariables>(
    useVariablesQuery.queryKey,
    useVariablesQuery.queryFn,
    {
      suspense,
      refetchOnMount,
      onError: defaultOnError,
      retry: (failureCount, error) => {
        const statusCode = (error as AxiosError).response?.status;
        return (statusCode === 400 || statusCode === 404) && failureCount <= 3;
      },
    }
  );

export enum IdentityType {
  IC_NUMBER = 'ic_number',
  OLD_IC_NUMBER = 'old_ic_number',
  PASSPORT = 'passport_number',
}

export const IdentityTypeLabel = {
  [`${IdentityType.IC_NUMBER}`]: 'IC number',
  [`${IdentityType.OLD_IC_NUMBER}`]: 'Old IC number',
  [`${IdentityType.PASSPORT}`]: 'Passport',
};

export type Profile = {
  email: string;
  phone: string;
  emailVerified: boolean;
  fullName: string;
  language: string; // TODO: enum
  id: string;
  createTimestamp: number;
  identityType?: IdentityType;
  identityNumber?: string;
};

export type ProfileMeta = {
  isNewUser?: boolean;
};

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BreadcrumbItem } from '~/shared/types';
import { breadcrumbSlice } from '~/store/breadcrumb';

export const useBreadcrumb = (breadcrumb?: BreadcrumbItem[]) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(breadcrumbSlice.actions.setBreadcrumb({ breadcrumb }));
    return () => {
      dispatch(
        breadcrumbSlice.actions.setBreadcrumb({
          breadcrumb: undefined,
        })
      );
    };
  }, [dispatch, breadcrumb]);
};

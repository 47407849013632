import { sdk } from '@setel/mini-app-sdk';
import axios, { AxiosRequestConfig } from 'axios';
import { TOKEN_NAME } from '../domain/auth/auth.service';
import { ApiAxiosError } from '../domain/error/error.type';
import { runTimeConfig as config } from './runtime-config';
import { apiMockSetup } from './api-mock';
import {
  isRefreshTokenRequest,
  refreshingToken,
  refreshToken,
} from './refreshToken';
import { HttpStatus } from './httpStatus';

export const apiClient = axios.create({
  baseURL: config.BASE_URL,
  timeout: 1000 * 30,
  withInterfaceHeaders: true,
});

const onRejectedResponse = (error: ApiAxiosError) => {
  if (error.response?.status === HttpStatus.UNAUTHORIZED) {
    return refreshToken(error).then(() => {
      applyAccessTokenHeader(error.config);
      return apiClient.request(error.config);
    });
  }

  throw error;
};

async function initialOnFulfilledRequest(requestConfig: AxiosRequestConfig) {
  if (requestConfig.withInterfaceHeaders) {
    requestConfig.headers['x-interface-id'] = 'setel';
    requestConfig.headers['x-interface-type'] = 'web';
  }

  await apiMockSetup.isReady;

  return requestConfig;
}

const initialRequestInterceptor = apiClient.interceptors.request.use(
  initialOnFulfilledRequest
);

const onFulfilledRequest = async (requestConfig: AxiosRequestConfig) => {
  await initialOnFulfilledRequest(requestConfig);

  if (!isRefreshTokenRequest(requestConfig) && !requestConfig.public) {
    await refreshingToken;
  }
  if (!requestConfig.public) {
    applyAccessTokenHeader(requestConfig);
  }

  return requestConfig;
};

const applyAccessTokenHeader = (requestConfig: AxiosRequestConfig) => {
  const token = sdk.auth.getToken();

  if (token && !requestConfig.public) {
    requestConfig.headers[TOKEN_NAME] = token.access_token;
  }
};

export function setupApiClientInterceptors() {
  apiClient.interceptors.response.use(
    (res) => res,
    (err) => onRejectedResponse(err)
  );
  apiClient.interceptors.request.use(onFulfilledRequest);
  apiClient.interceptors.request.eject(initialRequestInterceptor);
}

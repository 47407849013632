import { ApiAxiosError } from './error.type';
import { HttpStatus } from '../../shared/httpStatus';
import { ValidationError } from './ValidationError';

export const parseValidationError = (
  error: ApiAxiosError
): Record<string, string[]> => {
  const data = error.response?.data;
  if (!data) throw error;

  if (data.errors) {
    return data.errors;
  }

  if (Array.isArray(data.message)) {
    return data.message.reduce(
      (fields, { property, constraints }) => ({
        ...fields,
        [property]: [Object.values(constraints)],
      }),
      {}
    );
  }

  return {};
};

export const throwErrorData = (error: ApiAxiosError) =>
  Promise.reject(error.response?.data);

export const throwWPError = (error: ApiAxiosError) =>
  Promise.reject(
    new Error(
      `Wordpress Error: url: ${error.config.url} code: ${error.response?.status}`
    )
  );

export function getApiErrorMessage(error: unknown): string | undefined {
  if (typeof error === 'object' && error && 'response' in error) {
    const errMsg = (error as any).response?.data?.message;
    if (typeof errMsg === 'string') {
      return errMsg;
    }
  } else if (error instanceof Error) {
    return error.message;
  }
}

export async function getResponseErrorMessage(
  error: unknown
): Promise<string | undefined> {
  try {
    if (error instanceof Response) {
      const msg = (await error.json()).message;
      if (typeof msg === 'string') {
        return msg;
      }
    }
  } catch (err) {
    return undefined;
  }
}

export const getErrorStatusCode = (error: ApiAxiosError) =>
  error.response?.status as HttpStatus;

export const onlyValidationError =
  (cb: (error: ValidationError) => any) => (error: any) => {
    if (error instanceof ValidationError) return cb(error);
    throw error;
  };

export const defaultOnError = (error: any) => {
  // tslint:disable-next-line: no-console
  console.error(error);

  if (typeof window !== 'undefined') {
    window.newrelic?.noticeError(error);
  }
};

export const RETRY_COUNT = 3;
export const shouldRetryOnError = (failedCount: number, error: unknown) => {
  return (
    failedCount < RETRY_COUNT &&
    ![
      HttpStatus.UNAUTHORIZED,
      HttpStatus.BAD_REQUEST,
      HttpStatus.FORBIDDEN,
      HttpStatus.NOT_FOUND,
    ].includes(getErrorStatusCode(error as ApiAxiosError))
  );
};

import { useQuery } from 'react-query';
import { getProfile } from '~/domain/profile';
import { sdk } from '@setel/mini-app-sdk';
import { useMemo, useSyncExternalStore } from 'react';
import { useIsomorphicLayoutEffect, usePrevious } from '@reach/utils';
import jwt_decode from 'jwt-decode';

export function useUserProfile() {
  const token = useAuthToken();
  return useQuery(
    ['user/profile', Boolean(token)],
    ({ queryKey }) => {
      const [, hasToken] = queryKey;
      if (!hasToken) return null;
      return getProfile();
    },
    {
      keepPreviousData: true,
      notifyOnChangeProps: 'tracked',
    }
  );
}

export function useAuthToken() {
  return useSyncExternalStore(
    sdk.auth.listen,
    sdk.auth.getToken,
    getServerAuthToken
  );
}

function getServerAuthToken() {
  return undefined;
}

export function useReloadOnLogout() {
  const token = useAuthToken();
  const prevToken = usePrevious(token);

  useIsomorphicLayoutEffect(() => {
    if (prevToken && !token) {
      window.location.pathname = '/';
    }
  }, [token, prevToken]);
}

export function ReloadOnLogout() {
  useReloadOnLogout();
  return null;
}

export function useLoginProfile() {
  const token = useAuthToken();
  const decodedData = useMemo(
    () =>
      token?.access_token
        ? jwt_decode<{ sub: string }>(token.access_token)
        : undefined,
    [token?.access_token]
  );
  return { data: decodedData };
}

declare global {
  interface Window {
    dataLayer: EventDataProps[];
  }
}
interface EventDataProps {
  [key: string]: any;
}

export const setUser = (userId: string) => {
  window.dataLayer = window.dataLayer || [];
  // To access user id in GA4 reports & explorations
  // https://developers.google.com/analytics/devguides/collection/ga4/user-id?platform=websites#access_the_identifier
  window.dataLayer.push({ user_id: userId, crm_id: userId });
};

/**
 * @example
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
export const event = (
  action: string,
  {
    category,
    label,
    value,
    user_id,
    sendTo,
    allow_custom_scripts,
    ...metadata
  }:
    | {
        category?: string;
        label?: string;
        value?: string;
        user_id?: string;
        sendTo?: string;
        allow_custom_scripts?: boolean;
      }
    | { [key: string]: string }
) => {
  if (!window.gtag) {
    console.warn(
      'window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.'
    );
    return;
  }
  if (user_id) {
    setUser(user_id);
  }
  window.gtag('event', action, {
    ...metadata,
    send_to: sendTo,
    event_category: category,
    event_label: label,
    value: value,
  });
};

import { AxiosRequestConfig } from 'axios';
import { clearUserSession, updateSession } from '../domain/auth/auth.service';
import { ApiAxiosError } from '../domain/error/error.type';

export const isRefreshTokenRequest = ({ method, url }: AxiosRequestConfig) =>
  method === 'put' && url === '/idp/sessions';

export let refreshingToken: Promise<any> = Promise.resolve();
export let isTokenRefreshing = false;

const PANIC_AFTER_RETRY = 3;
let retryCount = 0;
let resetRetryTimer: number;

export const refreshToken = (error: ApiAxiosError) => {
  if (isRefreshTokenRequest(error.config)) throw error;
  if (retryCount > PANIC_AFTER_RETRY) throw error;

  if (isTokenRefreshing) return refreshingToken;

  isTokenRefreshing = true;

  window.clearTimeout(resetRetryTimer);
  retryCount++;
  resetRetryTimer = window.setTimeout(() => {
    // reset retry count after 10 sec
    retryCount = 0;
  }, 10000);

  refreshingToken = updateSession()
    .catch(() => {
      clearUserSession();
      refreshingToken = Promise.resolve();
      throw error;
    })
    .finally(() => (isTokenRefreshing = false));

  return refreshingToken;
};

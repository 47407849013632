import { type HTMLProps, useEffect, useRef } from 'react';

export interface Props
  extends Pick<HTMLProps<HTMLScriptElement>, 'src' | 'onLoad' | 'async'> {
  id: string;
  innerHTML?: string;
}

export function Script(props: Props) {
  const { src, innerHTML } = props;
  // re-rendering <script> tag won't have any effect, we chose to re-mount
  // when below listed props changed
  const key = [src, innerHTML].join('');
  return <InnerScript key={key} {...props} />;
}

function InnerScript(props: Props) {
  const scriptRef = useRef<HTMLScriptElement>();

  useEffect(
    () => {
      const el = document.createElement('script');
      for (const [k, v] of Object.entries(props)) {
        switch (true) {
          default:
            el.setAttribute(k, v);
            break;
          case k === 'innerHTML':
            el.textContent = v;
            break;
          case k === 'onLoad' && typeof v === 'function': {
            el.onload = v;
            break;
          }
        }
      }
      scriptRef.current = document.body.appendChild(el);
    },
    // don't depend on any props, because <script> re-render is useless,
    // we want re-mount instead so that new script's src/innerHTML got download/evaluated
    []
  );

  return <></>;
}

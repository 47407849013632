/* istanbul ignore file */

export const apiMockSetup: {
  isReady: boolean | Promise<unknown>;
} = {
  isReady: true,
};

if (process.env.IS_TEST === 'true' && process.env.ENABLE_MOCK === 'true') {
  apiMockSetup.isReady = import('../mocks').then(
    (mod) => mod.mockingResult as any
  );
}

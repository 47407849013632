import * as React from 'react';

/**
 * Check in runtime if the current code is executed on server or browser.
 *
 * If you want to render different UI, please use `useIsClient` custom hook instead.
 */
export const isClient = () =>
  !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );

/**
 * Custom hook that ensure the components has mounted on client side.
 *
 * Use this if you want to render different content on client side (probably due to component not SSR-friendly).
 */
export const useIsClient = () => {
  const [isClient, setIsClient] = React.useState(false);

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  return isClient;
};

import { sdk } from '@setel/mini-app-sdk';
import jwt_decode from 'jwt-decode';
import { getReCAPTCHAResponse } from '~/components/ReCAPTCHA';
import { HttpStatus } from '~/shared';
import * as analytic from '~/shared/analytics';
import { EVENT_TYPES } from '~/shared/analytics.events';
import { apiClient } from '~/shared/axios';
import { ADJUST_ATTRIBUTION_KEY } from '~/shared/constants';
import { runTimeConfig as config } from '~/shared/runtime-config';
import { AdjustAttribution } from '~/shared/types';
import {
  ApiAxiosError,
  defaultOnError,
  getErrorStatusCode,
  ValidationError,
} from '../error';
import {
  CreateAccountParams,
  CreateAccountPinResponse,
  CreateSessionByOtpParams,
  LoginResponse,
  OtpVerification,
  SendCreateSessionOtpParams,
  ValidatePromoCodeParams,
  VerifySessionOtpParams,
} from './auth.type';

export enum AuthErrorCode {
  USER_EXISTS = '3901004',
}

export const TOKEN_NAME = 'access-token';
export const IS_LOGGED_IN_BEFORE = 'is-logged-in-before';

export async function sendCreateSessionOtp(
  { phone, resend, voice }: SendCreateSessionOtpParams,
  {
    legacyDropInApi,
  }: {
    /** @deprecated */
    legacyDropInApi?: boolean;
  } = {}
) {
  const track = analytic.createEventTracker(
    resend
      ? EVENT_TYPES.authentication_resend_otp
      : EVENT_TYPES.authentication_request_otp,
    { phone, voice }
  );

  try {
    const recaptchaToken = await getReCAPTCHAResponse('submit');
    const resp = await apiClient.post(
      '/idp/sessions/web/send-otp',
      {
        phone,
        type: 'phone',
      },
      {
        public: true,
        params: { resend, voice },
        headers: {
          'x-recaptcha-token': recaptchaToken,
        },
      }
    );
    track({ status: 'success', http_code: resp.status });
    return resp;
  } catch (err) {
    if (err instanceof Error) {
      const error = err as ApiAxiosError;
      track({
        status: 'failed',
        http_code: getErrorStatusCode(error),
        http_error_message: error.response?.data?.message as string,
      });

      if (
        [HttpStatus.BAD_REQUEST, HttpStatus.TOO_MANY_REQUESTS].includes(
          getErrorStatusCode(error)
        )
      ) {
        throw new ValidationError({
          phone: [error.response?.data.message as string],
        });
      }

      throw error;
    }

    const error = new Error('Unknown error, please try again');
    track({
      status: 'failed',
      http_code: -1,
      http_error_message: error.message,
    });
    throw error;
  }
}

export async function verifySessionOtp(
  data: VerifySessionOtpParams,
  {
    legacyDropInApi,
  }: {
    /** @deprecated */
    legacyDropInApi?: boolean;
  } = {}
) {
  const track = analytic.createEventTracker(
    EVENT_TYPES.authentication_confirm_otp,
    { phone: data.phone }
  );

  try {
    const recaptchaToken = await getReCAPTCHAResponse('submit');
    const resp = await apiClient.post<OtpVerification>(
      '/idp/sessions/web/verify-otp',
      {
        ...data,
        type: 'phone',
      },
      {
        public: true,
        headers: {
          'x-recaptcha-token': recaptchaToken,
        },
      }
    );
    track({ status: 'success', http_code: resp.status });
    return resp;
  } catch (err) {
    if (err instanceof Error) {
      const error = err as ApiAxiosError;
      track({
        status: 'failed',
        http_code: getErrorStatusCode(error),
        http_error_message: error.response?.data?.message as string,
      });

      if (getErrorStatusCode(error) === HttpStatus.BAD_REQUEST) {
        throw new ValidationError({
          otp: [error.response?.data.message as string],
        });
      }

      throw error;
    }

    const error = new Error('Unknown error, please try again');
    track({
      status: 'failed',
      http_code: -1,
      http_error_message: error.message,
    });
    throw error;
  }
}

export async function createAccount({
  data,
  otpVerification,
}: {
  data: CreateAccountParams;
  otpVerification: OtpVerification;
}) {
  const recaptchaToken = await getReCAPTCHAResponse('submit');
  const mixpanel = await analytic.getMixpanel();

  let adjustAttribution: AdjustAttribution;
  let adjust = {};

  try {
    adjustAttribution = JSON.parse(
      localStorage.getItem(ADJUST_ATTRIBUTION_KEY) || ''
    );
    adjust = {
      adjustAdId: adjustAttribution.adid,
      adjustNetwork: adjustAttribution.network,
      adjustTrackerToken: adjustAttribution.tracker_token,
      adjustTrackerName: adjustAttribution.tracker_name,
    };
  } catch (ex) {}

  return apiClient
    .post<LoginResponse>(
      '/idp/registration/web/create-account',
      { ...data, adjust },
      {
        public: true,
        headers: {
          ...(!localStorage.getItem(IS_LOGGED_IN_BEFORE) &&
            config.ANALYTIC.MIXPANEL.ENABLED && {
              'x-distinct-id': mixpanel.get_distinct_id(),
            }),
          'x-recaptcha-token': recaptchaToken,
          'otp-token': otpVerification.otpToken,
          'phone-token': otpVerification.phoneToken,
          'x-registration-url': window?.location?.href,
        },
      }
    )
    .then((resp) => {
      const decodedData = jwt_decode(resp.data.access_token) as {
        [key: string]: any;
      };
      analytic.register({
        distinctId: resp.data.distinctId,
        userId: decodedData.sub,
        ...data,
      });
      sdk.auth.setToken(resp.data);
      localStorage.setItem(IS_LOGGED_IN_BEFORE, String(true));
      return resp;
    });
}

export function setPromoCode(promoCode: string) {
  return apiClient.put('/idp/registration/promo-code', { promoCode });
}

export function validatePromoCode({
  promoCode,
  otpToken,
  phoneToken,
}: ValidatePromoCodeParams) {
  return apiClient
    .put(
      '/idp/registration/promo-code/validate',
      { promoCode },
      {
        headers: {
          'otp-token': otpToken,
          'phone-token': phoneToken,
        },
      }
    )
    .catch((error: ApiAxiosError) => {
      if (getErrorStatusCode(error) === HttpStatus.BAD_REQUEST) {
        throw new ValidationError({
          promoCode: [error.response?.data.message as string],
        });
      }

      throw error;
    });
}

export async function createSessionByOtp(
  { phone, otpToken, phoneToken }: CreateSessionByOtpParams,
  {
    legacyDropInApi,
  }: {
    /** @deprecated */
    legacyDropInApi?: boolean;
  } = {}
) {
  try {
    const recaptchaToken = await getReCAPTCHAResponse('submit');
    const resp = await apiClient.post<LoginResponse>(
      '/idp/sessions/web',
      { phone },
      {
        public: true,
        headers: {
          'otp-token': otpToken,
          'phone-token': phoneToken,
          'x-recaptcha-token': recaptchaToken,
        },
      }
    );
    const decodedData = jwt_decode(resp.data.access_token) as {
      [key: string]: any;
    };
    await analytic.switchUser({
      distinctId: resp.data.distinctId,
      userId: decodedData.sub,
    });

    sdk.auth.setToken(resp.data);
    localStorage.setItem(IS_LOGGED_IN_BEFORE, String(true));
    return resp;
  } catch (err) {
    defaultOnError(err);
    throw err;
  }
}

export function updateSession() {
  const token = sdk.auth.getToken();

  if (!token) return Promise.reject(new Error('Invalid token'));
  return apiClient
    .put(
      '/idp/sessions',
      {},
      {
        public: true,
        headers: {
          refresh_token: token.refresh_token,
        },
      }
    )
    .then((resp) => {
      sdk.auth.setToken(resp.data);
      return resp;
    });
}

export function destroySession() {
  return apiClient.delete('/idp/sessions').then(() => clearUserSession());
}

export function clearUserSession() {
  sdk.auth.clearToken();
}

export function getUserAuthToken() {
  return sdk.auth.getToken();
}

export const createAccountPin = (pin: string) => {
  return apiClient
    .post<CreateAccountPinResponse>('/idp/accounts/pin', { token: pin })
    .then((resp) => resp)
    .catch((error) => {
      if ([HttpStatus.BAD_REQUEST].includes(getErrorStatusCode(error))) {
        throw new ValidationError({
          idNumber: [error.response?.data.message as string],
        });
      }

      throw error;
    });
};

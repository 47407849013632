import { type QueryClient, useQuery } from 'react-query';

export const useIsNewlyRegistered = () => {
  const { data: isNew } = useQuery<boolean>('userIsNew', {
    initialData: false,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
  return Boolean(isNew);
};

export function setUserIsNew(client: QueryClient) {
  client.setQueryData('userIsNew', true);
}

export enum EVENT_CHANNELS {
  MIXPANEL = 'mixpanel',
  GA = 'ga',
  ADJUST = 'adjust',
  MESRA_META_PIXEL = 'mesra_meta_pixel',
  MESRA_DV360_GTAG = 'mesra_dv360_gtag',
  MESRA_GOOGLE_GTAG = 'mesra_google_gtag',
}

export enum EVENT_TYPES {
  WEB_GAMIFICATION_BADGE_VIEW_BADGE_GALLERY = 'web_gamification_badge_view_badge_gallery',
  WEB_GAMIFICATION_BADGE_VIEW_BADGE_DETAILS = 'web_gamification_badge_view_badge_details',
  NOTIFICATION_VIEW_SETTINGS = 'web_reach_view_notification_setting',
  // keys below match api-attributes TS constants for easy value mapping
  reach_notification_email_payment_receipts = 'web_reach_notification_update_email_payment_receipts',
  reach_notification_email_top_up_receipt = 'web_reach_notification_update_email_topup',
  reach_notification_email_fuel_receipt = 'web_reach_notification_update_email_fuel_receipt',
  reach_notification_email_monthly_statement = 'web_reach_notification_update_email_monthly_statement',
  reach_notification_email_marketing = 'web_reach_notification_update_email_marketing',

  deal_deal_order_view_main_page = 'web_deal_deal_order_view_main_page',
  deal_deal_order_view_catalogue = 'web_deal_deal_order_view_catalogue',
  deal_deal_order_view_deals_detail = 'web_deal_deal_order_view_deals_detail',

  authentication_request_otp = 'web_app_authentication_request_otp',
  authentication_resend_otp = 'web_app_authentication_resend_otp',
  authentication_confirm_otp = 'web_app_authentication_confirm_otp',

  login = 'web_login',
  first_login = 'web_login_first_time',
  register = 'web_register',
  pageview = 'web_pageview',
  sms_me_the_app_main_banner = 'web_sms_me_the_app_main_banner',
  sms_me_the_app_footer = 'web_sms_me_the_app_footer',
  get_app = 'web_get_app',

  // only for GA
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#spend_virtual_currency
  spend_virtual_currency = 'spend_virtual_currency',
  cardless_mesra_pageview_enter_mobile_number = 'web_cardless_mesra_pageview_enter_mobile_number',
  cardless_mesra_click_activate_now = 'web_cardless_mesra_click_activate_now',
  cardless_mesra_pageview_landing_page = 'web_cardless_mesra_pageview_landing_page',
  cardless_mesra_continue_enter_mobile_number = 'web_cardless_mesra_continue_enter_mobile_number',
  cardless_mesra_pageview_enter_verification_code = 'web_cardless_mesra_pageview_enter_verification_code',
  cardless_mesra_pageview_introduce_yourself = 'web_cardless_mesra_pageview_introduce_yourself',
  cardless_mesra_continue_introduce_yourself = 'web_cardless_mesra_continue_introduce_yourself',
  cardless_mesra_pageview_enter_new_passcode = 'web_cardless_mesra_pageview_enter_new_passcode',
  cardless_mesra_pageview_reenter_new_passcode = 'web_cardless_mesra_pageview_re-enter_new_passcode',
  cardless_mesra_pageview_activate_mesra_card = 'web_cardless_mesra_pageview_activate_mesra_card',
  cardless_mesra_continue_activate_mesra_card = 'web_cardless_mesra_continue_activate_mesra_card',
  cardless_mesra_pageview_existing_mesra_member = 'web_cardless_mesra_pageview_existing_mesra_member',
  cardless_mesra_link_and_activate = 'web_cardless_mesra_link_and_activate',
  cardless_mesra_pageview_confirmation_to_link_and_activate = 'web_cardless_mesra_pageview_confirmation_to_link_and_activate',
  cardless_mesra_confirm_link_and_activate = 'web_cardless_mesra_confirm_link_and_activate',
  cardless_mesra_pageview_multiple_mesra = 'web_cardless_mesra_pageview_multiple_mesra',
  cardless_mesra_pageview_frozen_mesra_card = 'web_cardless_mesra_pageview_frozen_mesra_card',
  cardless_mesra_pageview_activated_mesra_card = 'web_cardless_mesra_pageview_activated_mesra_card',
  cardless_mesra_pageview_chat = 'web_cardless_mesra_pageview_chat',
  cardless_mesra_start_chat = 'web_cardless_mesra_start_chat',
  cardless_mesra_pageview_linked_mesra_card = 'web_cardless_mesra_pageview_linked_mesra_card',

  reward_scan_mesra_barcode_success = 'web_reward_scan_mesra_barcode_success',
  reward_scan_mesra_barcode_failed = 'web_reward_scan_mesra_barcode_failed',
}

export function isCardlessMesraEvents(eventName: EVENT_TYPES) {
  return (eventName || '').startsWith('web_cardless_mesra_');
}

export const ADJUST_EVENT_TOKENS: Partial<
  Record<EVENT_TYPES, { sandbox?: string; production?: string }>
> = {
  // Adjust events have to be created on Adjust Dashboard & referenced by
  // event token (you'll see event token once it's created on Adjust Dashboard)
  // https://github.com/adjust/web_sdk#event-tracking
  web_get_app: { sandbox: 'q8360q', production: 'wwlqzv' },
  web_sms_me_the_app_main_banner: { sandbox: 'q0lvl6', production: 'u8ef7e' },
  web_sms_me_the_app_footer: { sandbox: 'tk6nfk', production: 'd2d28q' },
} as const;

export enum EVENT_SOURCES {
  // event's source for deal
  deal_catalogue_page = 'catalogue_page',
  deal_main_page = 'deals_main_page',
  deal_main_page_catalogue_view_more = 'view_more',
}

import { useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from '@remix-run/react';
import type { To } from '@remix-run/router';

export function useRouter() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  const query = useMemo(() => Object.fromEntries(searchParams), [searchParams]);

  return useMemo(
    () => ({
      pathname,
      query,
      push: navigate,
      back: () => navigate(-1),
      replace: (to: To) => navigate(to, { replace: true }),
      searchParams,
    }),
    [pathname, query]
  );
}

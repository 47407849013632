import * as React from 'react';
import { runTimeConfig } from '~/shared/runtime-config';
import { Script } from './Script';
import { createEventBus } from '@setel/web-ui';
import { useQuery } from 'react-query';
import { useVariables } from '~/domain/variable';

const grecaptchaHref = `https://www.google.com/recaptcha/api.js?render=${runTimeConfig.RE_CAPTCHA_SITE_KEY}`;

const recaptchaScriptLoadedEvtBus = createEventBus<unknown[]>();

export function ReCAPTCHAScript() {
  return (
    <>
      <HidesReCAPTCHABadge />
      <Script
        id="g-recaptcha-script"
        src={grecaptchaHref}
        onLoad={() => {
          recaptchaScriptLoadedEvtBus.emit();
        }}
      />
    </>
  );
}

function onReCAPTCHALoaded() {
  return new Promise<void>((resolve) => {
    if (!window.grecaptcha) {
      recaptchaScriptLoadedEvtBus.listen(() => {
        window.grecaptcha.ready(() => resolve());
      });
    } else {
      window.grecaptcha.ready(() => resolve());
    }
  });
}

export function useReCAPTCHA() {
  const [response, setResponse] = React.useState<
    string | Error | null | undefined
  >(null);
  const isIdle = response === null;
  const isSubmitting = response === undefined;
  const isSuccess = typeof response === 'string';
  const isError = response instanceof Error;
  const error = isError ? response : undefined;

  const setSubmitting = React.useCallback(() => setResponse(undefined), []);

  const { data: variables } = useVariables({
    suspense: true,
    refetchOnMount: 'always',
  });
  const recaptchaFlagEnabled = Boolean(
    variables?.web_enable_recaptcha?.value ?? true // enabled by default
  );

  // leverage react-query for easy suspense setup
  const { data: grecaptchaRenderResult } = useQuery({
    suspense: true,
    queryKey: 'grecaptcha.render',
    staleTime: Infinity, // prevents refetch
    queryFn: async () => {
      if (!recaptchaFlagEnabled) {
        return;
      }
      return renderReCAPTCHA();
    },
  });
  const {
    submit: submitReCAPTCHA,
    onResponse,
    cleanup,
  } = grecaptchaRenderResult!;

  React.useEffect(() => {
    // since these vars are returned from suspense-ed useQuery, they won't change overtime
    const unsubcribe = onResponse((response) => setResponse(response));
    return () => {
      unsubcribe();
      cleanup();
    };
  }, []);

  const execute = React.useCallback(() => {
    if (!recaptchaFlagEnabled) {
      // use setTimeout to slightly delay state transition,
      // otherwise <form> might not be submitted immediately because the form has been
      // continously submitted (first submission triggers recaptcha & then actual submit)
      setTimeout(() => setResponse('_'), 100);
      return;
    }
    submitReCAPTCHA();
    setSubmitting();
  }, [setSubmitting, submitReCAPTCHA, recaptchaFlagEnabled]);

  return {
    execute,
    response,
    isIdle,
    isSubmitting,
    isSuccess,
    isError,
    error,
  };
}

async function renderReCAPTCHA() {
  await onReCAPTCHALoaded();

  const grecaptchaButton = document.createElement('button');
  grecaptchaButton.classList.add('sr-only');
  grecaptchaButton.tabIndex = -1;
  document.body.appendChild(grecaptchaButton);

  // Setup grecaptcha response evt bus & listen, response will be fired at some point after calling
  // submit() method.
  const responseEvtBus = createEventBus<(string | Error)[]>();

  const widgetId = window.grecaptcha.render(grecaptchaButton, {
    sitekey: runTimeConfig.RE_CAPTCHA_SITE_KEY,
    tabindex: -1,
    isolated: true,
    size: 'invisible',
    callback(token) {
      responseEvtBus.emit(token);
    },
    'expired-callback'() {
      responseEvtBus.emit(
        new Error('reCAPTCHA tokens expired, please try again')
      );
    },
    'error-callback'() {
      responseEvtBus.emit(new Error('reCAPTCHA error, please try again'));
    },
  });

  return {
    submit: () => grecaptchaButton.click(),
    onResponse: responseEvtBus.listen,
    cleanup: () => {
      try {
        window.grecaptcha?.reset(widgetId);
        grecaptchaButton.remove();
      } catch (err) {
        console.warn(err);
      }
    },
  };
}

export function getReCAPTCHAResponse(action: ReCaptchaV2.Action['action']) {
  let retryCount = 1;
  function executeReCaptcha(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(runTimeConfig.RE_CAPTCHA_SITE_KEY, { action })
          .then(
            (value) => resolve(value),
            (reason) => reject(reason)
          );
      });
    }).catch((err) => {
      if (!err) {
        // this usually means grecaptcha.execute failed for unknown reason (`null` rejected), retry.
        if (retryCount > 0) {
          retryCount--;
          return executeReCaptcha();
        }
        // out of retry count, throw a descriptive error
        throw new Error('Unable to execute grecaptcha, please try again');
      }
      throw err;
    });
  }
  return executeReCaptcha();
}

function HidesReCAPTCHABadge() {
  React.useEffect(() => {
    /* hides recaptcha badge
     * https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
     */
    let css = '.grecaptcha-badge { visibility: hidden !important; }';
    let link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = `data:text/css,${encodeURIComponent(css)}`;
    document.head.appendChild(link);

    return () => {
      link.remove();
    };
  }, []);

  return null;
}

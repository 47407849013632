import { useCallback, useEffect, useRef } from 'react';

export type UseIntersectionObserverType = {
  callback(
    entries: IntersectionObserverEntry,
    observer: IntersectionObserver | null | undefined
  ): void;
  root?: Element | null;
  rootMargin?: string;
  threshold?: number;
};

export const useIntersectionObserver = ({
  root,
  rootMargin,
  threshold = 0.1,
  callback,
}: UseIntersectionObserverType) => {
  const observer = useRef<IntersectionObserver | null>();

  const setTarget = useCallback(
    (target: Element | null) => {
      if (!target) return;
      observer.current?.disconnect();
      observer.current = new IntersectionObserver(
        ([entry]) => callback(entry, observer.current),
        {
          root,
          rootMargin,
          threshold,
        }
      );
      observer.current.observe(target);
    },
    [callback, root, rootMargin, threshold]
  );

  useEffect(() => {
    return () => {
      observer.current?.disconnect();
      observer.current = null;
    };
  }, []);

  return setTarget;
};

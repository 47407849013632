import { BreadcrumbItem } from '~/shared/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BreadcrumbState = {
  breadcrumb: undefined | BreadcrumbItem[];
};

const initialState: BreadcrumbState = {
  breadcrumb: undefined,
};

export const breadcrumbSlice = createSlice({
  name: 'breadcrumbState',
  initialState,
  reducers: {
    setBreadcrumb: (state, action: PayloadAction<BreadcrumbState>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

import { useEffect, useState } from 'react';
import allSelectors, {
  getSelectorsByUserAgent,
  getUA,
} from 'react-device-detect';
import type { FilterStartsWith } from '~/shared/types';

interface DeviceSelectors
  extends Pick<
    typeof allSelectors,
    FilterStartsWith<
      keyof typeof allSelectors,
      'is' // only selects boolean selectors
    >
  > {
  isHuawei?: boolean;
}

export function useDeviceDetect() {
  const [selectors, setSelectors] = useState<Partial<DeviceSelectors>>(
    () => ({})
  );

  useEffect(() => {
    const data = getSelectorsByUserAgent(window.navigator.userAgent);
    setSelectors({
      ...data,
      isHuawei: ['huawei', 'hms', 'gms'].some((x) =>
        getUA.toLowerCase().includes(x)
      ),
    });
  }, []);

  return selectors;
}

import { MINI_APP, UTM_MEDIUM } from './constants';

export function convertMinutesToTime(mins: number): string {
  let hour = Math.floor(mins / 60);
  let minute: string | number = mins % 60;
  minute = minute < 10 ? '0' + minute : minute;
  let amOrPm = 'AM';

  if (hour >= 12 && hour < 24) {
    amOrPm = 'PM';
  }

  if (hour > 12) {
    hour = hour - 12;
  }

  return `${hour}:${minute} ${amOrPm}`;
}

export const truncate = (str: string, maxChars = 200) =>
  str.length > maxChars ? `${str.slice(0, maxChars)}...` : str;

export const copyToClipboard = (content: string): boolean => {
  const textArea = document.createElement('textarea');

  Object.assign(textArea.style, {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '2em',
    height: '2em',
    padding: '0',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    background: 'transparent',
  });

  textArea.value = content;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    return document.execCommand('copy');
  } catch {
    return false;
  } finally {
    document.body.removeChild(textArea);
  }
};

export function isMobile(): boolean {
  return (
    /Mobi/i.test(navigator.userAgent) ||
    window.matchMedia('only screen and (max-width: 640px)').matches
  );
}

export function debounce(fn: any, ms = 0) {
  let timeoutId: NodeJS.Timeout;
  return function (...args: any) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(null, args), ms);
  };
}

type Callback<Args extends any[]> = (...args: Args) => void;
export function callAll<Args extends any[]>(
  ...fns: (Callback<Args> | undefined | boolean | null)[]
): Callback<Args> {
  return function callAllFns() {
    const arg = arguments;
    fns.forEach(
      (fn) => typeof fn === 'function' && fn.apply(null, arg as any as Args)
    );
  };
}

export const wait = (ms: number) => new Promise((r) => setTimeout(r, ms));

export function youtubePosterUrl(
  videoId: string,
  resolution?: 'maxresdefault' | 'sddefault' | 'hqdefault' // https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api
) {
  return `https://i.ytimg.com/vi/${videoId}/${resolution || 'hqdefault'}.jpg`;
}

export function isMiniApp(searchParams: URLSearchParams) {
  return searchParams.get(UTM_MEDIUM) === MINI_APP;
}

interface HrefObj {
  href: string;
}

export interface ImgObj {
  sm: HrefObj;
  md: HrefObj;
  lg: HrefObj;
}

export const getPreloadLinks = (imgObject: ImgObj) => {
  return Object.values(imgObject).map((l) => ({
    ...l,
    rel: 'preload',
    as: 'image',
    type: 'image/png',
  }));
};

export function jsonParse<T = any>(
  value: string,
  shouldReturnNull = false
): T | string | null {
  try {
    return JSON.parse(value);
  } catch (err) {
    return shouldReturnNull ? null : value;
  }
}

import { MouseEventHandler, RefObject, useCallback, useRef } from 'react';

export function useContainerLink({
  excludeRefs,
}: {
  excludeRefs?: RefObject<HTMLElement>[];
} = {}) {
  const anchorRef = useRef<HTMLAnchorElement>(null);

  const handleClick: MouseEventHandler = useCallback(
    (e) => {
      const evtTarget = e.target as Node;
      const isTextSelected = window?.getSelection()?.toString();

      if (
        isTextSelected ||
        anchorRef.current?.isSameNode(evtTarget) ||
        // find out whether current node is one of excludeRefs' nodes
        excludeRefs?.some((ref) => ref.current?.isSameNode(evtTarget))
      ) {
        return;
      }

      // detect "open link in new tab"
      if (
        e.ctrlKey ||
        e.shiftKey ||
        e.metaKey || // apple
        (e.button && e.button === 1) // middle click, >IE9 + everyone else
      ) {
        window.open(anchorRef.current?.href, '_blank');
        return;
      }
      // otherwise normal click
      anchorRef.current?.click();
    },
    [excludeRefs?.length]
  );

  return { anchorRef, handleClick };
}

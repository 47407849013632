import { apiClient } from '../../shared/axios';
import { Profile } from './profile.type';

export const getProfile = () => {
  return apiClient.get<Profile>('/idp/accounts').then((resp) => resp.data);
};

export const updateProfile = (data: Partial<Profile>) => {
  return apiClient
    .put<Profile>('/idp/accounts', data)
    .then((resp) => resp.data);
};

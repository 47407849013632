import { useEffect } from 'react';
import * as analytics from '~/shared/analytics';
import { useLocation } from '@remix-run/react';
import { useUserProfile } from '~/features/Auth/hooks/useUserProfile';

export const useAnalyticsTracking = () => {
  const { pathname } = useLocation();
  const { data: profile } = useUserProfile();

  analytics.useInit();

  useEffect(() => {
    analytics.sendPageview(pathname);
  }, [pathname]);

  useEffect(() => {
    if (profile?.id) {
      analytics.setUser(profile.id);
    }
  }, [profile?.id]);
};

export function Analytics() {
  useAnalyticsTracking();
  return null;
}

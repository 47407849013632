import { usePrevious } from '@reach/utils';
import { useLocation } from '@remix-run/react';

export function useRemixHasNavigated() {
  const { pathname, search, hash } = useLocation();

  const loc = pathname + search + hash;
  const prevLoc = usePrevious(loc);

  return prevLoc !== loc;
}

import { useCallback, useEffect } from 'react';

export function useBodyScrollLock() {
  const disable = useCallback(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  const enable = useCallback(() => {
    document.body.style.overflow = '';
  }, []);

  useEffect(() => {
    return () => enable();
  }, []);

  return { disable, enable };
}

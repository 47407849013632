export enum WebVariableType {
  SUBMIT_RECEIPT_PATH = 'web_retail_external_order_submit_receipt_url_path',
  SUBMIT_RECEIPT_FEATURE = 'web_retail_external_order_submit_receipt_feature',
  MESRA_WELCOME_MODAL_HIDDEN = 'web_mesrarewards_welcome_modal_hidden',
  PREFER_CDN_IMAGE_RESIZE = 'web_image_prefer_cdn_resize',
  LEGACY_DROPIN_API = 'web_prefer_legacy_dropin_api',
  ENABLE_RE_CAPTCHA = 'web_enable_recaptcha',
  ENABLE_SPA = 'web_enable_spa',
  MESRA_BARCODE_VISIBILITY = 'setel_web_mesra_card_barcode_visibility',
  ENABLE_MESRA_REWARDS_DEALS_SPLIT_PAYMENT_BANNER = 'web_mesra_rewards_deals_split_payment_banner',
}

export type IWebVariables = Partial<Record<WebVariableType, IVariable>>;

type IVariable = {
  key: string;
  value: boolean | JSON | string;
  type: string;
};
